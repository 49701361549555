@use '@angular/material' as mat;

@mixin idl-styles-global-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  .row-highlight {
    background: mat.get-color-from-palette($accent, 300);
  }

  .mat-mdc-row:hover {
    color: mat.get-color-from-palette($accent, darker-contrast) !important;
    background-color: mat.get-color-from-palette($accent, darker) !important;
  }

  .mat-mdc-input-element {
    caret-color: mat.get-color-from-palette($foreground, text) !important;
  }
}
