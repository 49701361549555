@use '@angular/material'as mat;
@import 'styles-variables';

@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'styles-reset';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

@import './themes/black-theme';
@import './themes/interdynamics-theme';

@import 'styles-reset.scss-theme';
@import './app/app/app.component.scss-theme';
@import './app/features/about/about/about.component.scss-theme';

@import './styles-global.scss-theme.scss';

@mixin custom-components-theme($theme) {
  @include ait-styles-reset-theme($theme);
  @include ait-app-component-theme($theme);
  @include ait-about-component-theme($theme);
  @include idl-styles-global-theme($theme);
}

.black-theme {
  @include mat.all-component-themes($ait-black-theme);
  @include custom-components-theme($ait-black-theme);
}

.interdynamics-theme {
  @include mat.all-component-themes($ait-interdynamics-theme);
  @include custom-components-theme($ait-interdynamics-theme);
}
